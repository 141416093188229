const setCookie = (cookieName, cookieValue, expiresInDays) => {
  const date = new Date();
  date.setTime(date.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/`;
};

const handleRedirect = (redirectURL, linkOpenType) => {
  window.open(redirectURL, linkOpenType);
};

const handleDownload = (downloadFileURL) => {
  window.open(downloadFileURL, '_blank');
};

const handleResourceSamePage = (
  isParentBlockGatedContentBlock,
  cookieValidity,
  currentPostId,
) => {
  if (isParentBlockGatedContentBlock) {
    setCookie(`postIds_${currentPostId}`, true, cookieValidity);
  }

  setTimeout(() => {
    window.location.reload(true);
  }, 1000);
};

const handlePopup = (
  popupVideo,
  popupVideoURL,
  popupVideoUploadURL,
  customVideoScript,
  defaultClass,
  embeddedFormId,
) => {
  if (
    (popupVideo === 'url' && popupVideoURL) ||
    (popupVideo === 'upload' && popupVideoUploadURL) ||
    (popupVideo === 'embedded' && customVideoScript)
  ) {
    document
      .getElementById(`${defaultClass}_fancybox_${embeddedFormId}`)
      .click();
  }
};

const handleThankYouDownloadCta = (
  currentPostId,
  redirectDownloadUrl,
  redirectDownloadFileUrl,
  downloadButtonLabel,
) => {
  const cookieName = `hs_form_${currentPostId}`;
  setCookie(cookieName, 'true', 1);

  const encodedParams = btoa(redirectDownloadFileUrl);
  const encodedPostId = btoa(currentPostId);
  const label = downloadButtonLabel?.trim() || '';

  window.open(
    `${redirectDownloadUrl}?file=${encodedParams}&postId=${encodedPostId}&label=${encodeURIComponent(
      label,
    )}`,
    '_self',
  );
};

const redirectHandler = (props) => {
  const {
    responseType,
    redirectURL,
    downloadFileURL,
    linkOpenType,
    cookieValidity,
    popupVideo,
    popupVideoURL,
    popupVideoUploadURL,
    customVideoScript,
    defaultClass,
    embeddedFormId,
    currentPostId,
    isParentBlockGatedContentBlock,
    redirectDownloadUrl,
    redirectDownloadFileUrl,
    downloadButtonLabel,
  } = props;

  if (responseType === 'redirect' && redirectURL) {
    handleRedirect(redirectURL, linkOpenType);
  } else if (responseType === 'download' && downloadFileURL) {
    handleDownload(downloadFileURL);
  } else if (responseType === 'resource-same-page') {
    handleResourceSamePage(
      isParentBlockGatedContentBlock,
      cookieValidity,
      currentPostId,
    );
  } else if (responseType === 'popup') {
    handlePopup(
      popupVideo,
      popupVideoURL,
      popupVideoUploadURL,
      customVideoScript,
      defaultClass,
      embeddedFormId,
    );
  } else if (responseType === 'thankYouDownloadCta') {
    handleThankYouDownloadCta(
      currentPostId,
      redirectDownloadUrl,
      redirectDownloadFileUrl,
      downloadButtonLabel,
    );
  }
};

export default redirectHandler;
