import redirectHandler from './redirect-handler';
const { feWp, hbspt } = window;
const { applyFilters } = feWp.hooks;
const { doAction } = wp.hooks;

const b3rgEfWpHubspotFormSubmittedHandler = (embeddedFormDataObj) => {
  redirectHandler(embeddedFormDataObj);
};

const b3rgEfWpHubspotFormSubmittedEvent = (embeddedFormDataObj) => {
  const {
    responseMessage,
    portalId,
    formId,
    sfdcCampaignId,
    enableCss,
    regionId,
    embeddedFormId,
  } = embeddedFormDataObj;

  hbspt.forms.create({
    region: regionId,
    portalId,
    formId,
    sfdcCampaignId,
    inlineMessage: responseMessage,
    css: enableCss ? true : null,
    target: `#e-emf-${embeddedFormId}__form`,
    onFormSubmitted: () => {
      const formSubmittedHandler = applyFilters(
        'b3rg_embedded_form_hubspot_form_submitted_handler',
        b3rgEfWpHubspotFormSubmittedHandler,
        embeddedFormDataObj,
      );

      doAction(
        'b3rg_embedded_form_hubspot_form_submitted_event',
        embeddedFormDataObj,
        hbspt,
      );

      if (typeof formSubmittedHandler === 'function') {
        formSubmittedHandler(embeddedFormDataObj);
      }
    },
  });
};

(() => {
  const localizedObjectPrefix = applyFilters(
    'b3rg_embedded_form_hubspot_script_localized_object_prefix',
    'embedded_forms_hubspot',
  );

  const eventBindingFn = applyFilters(
    'b3rg_embedded_form_hubspot_event_binder',
    b3rgEfWpHubspotFormSubmittedEvent,
  );

  if (typeof eventBindingFn === 'function') {
    Object.keys(window).forEach((windowVar) => {
      if (windowVar.startsWith(`${localizedObjectPrefix}_`)) {
        eventBindingFn(window[windowVar]);
      }
    });
  }
})();
